import React, { useEffect, useMemo, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import EditorData from 'components/editor-data/EditorData';
import cloneDeep from 'helpers/cloneDeep';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import FormatsList from '../../formats-list';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import { CreativeV2Template } from '../types/creativeV2.type';

import '../styles/header.scss';

interface ComponentStoreProps {
    sidebarView: CreativeEditorV2['sidebarView'];
    creative: CreativeEditorV2['creative'];
    disabledFeatures: CreativeEditorV2['disabledFeatures'];
}

interface Props {
    closeEditor: () => void;
    showSave?: boolean;
    onSaveCreative?: (creative: CreativeV2Template) => void;
}

const CreativeV2EditorHeader: React.FC<Props> = ({ closeEditor, showSave = true, onSaveCreative }) => {
    const [formatsListOpen, setFormatsListOpen] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState<{ key: string; label: string }[]>([{ key: 'EN', label: 'English' }]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const languageSelectorOpen = Boolean(anchorEl);

    const { sidebarView, creative, disabledFeatures } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            sidebarView: 'sidebarView',
            creative: 'creative',
            disabledFeatures: 'disabledFeatures'
        }
    });

    const formatList = useMemo(() => {
        const formats = CreativeV2FormatHelpers.getFormats(creative.data.templateIdentifier);
        return formats || [];
    }, [creative.data.templateIdentifier]);

    useEffect(() => {
        const languages = EditorData.getValueFromModel('settings.languages');

        if (!languages) return;

        setAvailableLanguages(
            Object.keys(languages).map((key) => {
                return {
                    key: key,
                    label: languages[key]
                };
            })
        );
    }, []);

    const openFormatsListDrawer = () => {
        setFormatsListOpen(true);
    };

    const closeFormatsListDrawer = () => {
        setFormatsListOpen(false);
    };

    const toggleComments = () => {
        const newSidebarView = sidebarView === 'comments' ? 'interface' : 'comments';
        ComponentStore.setModel('CreativeEditorV2', 'sidebarView', newSidebarView);
    };

    const saveCreative = () => {
        const creative = ComponentStore.getItem<CreativeEditorV2['creative']>('CreativeEditorV2', 'creative');
        if (!creative) return;

        const clonedCreative: CreativeV2Template = cloneDeep(creative);

        onSaveCreative && onSaveCreative(clonedCreative);

        SnackbarUtils.success('Creative saved');
        ComponentStoreHelpers.setModel('CreativeEditorV2', 'changedAfterSave', false);
    };

    const openLanguageSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeLanguageSelector = () => {
        setAnchorEl(null);
    };

    const onSelectLanguage = (newLanguage: string) => {
        // We set the data in the old editor state, so that all the inputs work out of the box
        EditorData.setEditorState('language', newLanguage);
        closeLanguageSelector();
    };

    // todo helper (formatselector has the same)
    const getFilters = (): string[] | undefined => {
        const formatSets = (TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier) as TDTemplateAsset)?.data.settings?.formatSets;

        if (!formatSets || !Array.isArray(formatSets)) return;

        return formatSets.map((format) => format.title).filter((set) => set !== 'Unset');
    };

    return (
        <div className="creative-editor-v2-header">
            {!disabledFeatures.includes('close') && (
                <IconButton className="creative-editor-v2-header__arrow-back" onClick={closeEditor} size="small">
                    <Icon fontSize="small">arrow_back</Icon>
                </IconButton>
            )}

            {!disabledFeatures.includes('formatSelector') && (
                <Button variant="outlined" onClick={() => openFormatsListDrawer()} startIcon={<Icon fontSize="small">list_alt_add</Icon>}>
                    {Translation.get('titles.manageFormats', 'creatives-v2')}
                </Button>
            )}

            {!disabledFeatures.includes('languages') && (
                <>
                    <Button
                        className="creative-editor-v2-header__arrow-button"
                        onClick={openLanguageSelector}
                        endIcon={<Icon fontSize="small">expand_more</Icon>}>
                        {availableLanguages.find((lang) => lang.key === EditorData.getLanguage())?.label || 'English'}
                    </Button>
                    <Menu anchorEl={anchorEl} open={languageSelectorOpen} onClose={closeLanguageSelector}>
                        {availableLanguages.map((language) => (
                            <MenuItem key={language.key} onClick={() => onSelectLanguage(language.key)}>
                                {language.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}

            <div className="creative-editor-v2-header__actions-right">
                {!disabledFeatures.includes('comments') && (
                    <IconButton onClick={() => toggleComments()} color={sidebarView === 'comments' ? 'primary' : 'default'}>
                        <Icon>chat</Icon>
                    </IconButton>
                )}

                {showSave ? (
                    <Button className="creative-editor-v2-header__save" color="primary" variant="contained" onClick={saveCreative}>
                        {Translation.get('actions.save', 'common')}
                    </Button>
                ) : (
                    <IconButton className="creative-editor-v2-header__close" onClick={closeEditor} size="small">
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                )}
            </div>

            {formatsListOpen && (
                <Backdrop open onClick={() => closeFormatsListDrawer()} sx={{ zIndex: 10000 }}>
                    <div className="creative-editor-v2-header__formats-list-drawer" onClick={(e) => e.stopPropagation()}>
                        <FormatsList
                            items={formatList || []}
                            selectedItems={creative.data?.settings?.activeFormats}
                            filters={getFilters()}
                            onChangeSelectedItems={(newSelectedFormats: string[]) => CreativeV2FormatHelpers.updateActiveFormats(newSelectedFormats)}
                            onClose={() => closeFormatsListDrawer()}
                        />
                    </div>
                </Backdrop>
            )}
        </div>
    );
};

export { CreativeV2EditorHeader };

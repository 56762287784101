import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import { CreativeV2, CreativeV2Template } from '../creative-editor/types/creativeV2.type';
import ZoomBar from './components/zoom-bar';
import { FramesBarFrame } from './components/frame';
import Canvas from './components/canvas';
import CreativeOverviewPlaybar from './components/play-bar';
import { CreativeViewType, ICreativeOverview } from './types/creative-overview.type';
import SingleViewSideBar from './components/single-view-side-bar';
import { DisabledFeature } from '../creative-editor/types/creativeEditorV2.type';
import './styles/main.scss';

const CreativeOverview: React.FC<CreativeOverviewProps> = ({
    creatives: propCreatives, // We always get creatives from the parent, but we store them in the ComponentStore for the rest of the components
    editable = false,
    availableViews = ['canvasFixed', 'canvasFree'],
    showEditCreativeButton = false,
    frameActions = {},
    iframeOverwrites,
    headerComponent,
    defaultView = 'canvasFixed',
    autoplayOnFirstOpen = false,
    disabledFeatures = [],
    defaultSingleViewKey,
    hideSingleViewSideBar = false,
    onSaveCreative
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { view, creatives, activeFrame } = useComponentStore<ComponentStoreProps>('CreativeOverview', {
        fields: {
            view: 'view',
            activeFrame: 'activeFrame',
            creatives: 'creatives'
        }
    });

    // Set the initial ComponentStore values
    useEffect(() => {
        ComponentStore.setData('CreativeOverview', {
            view: defaultView,
            canvasZoom: 1,
            playing: false,
            duration: 0,
            sceneKey: uuidv4(),
            activeFrame: 'frame1',
            disabledFeatures,
            singleViewKey: defaultSingleViewKey
        } as ICreativeOverview);

        return () => {
            // Don't remove the ComponentStore every time the component unmounts, because in some cases
            // we want to keep the data in the store (e.g. creative tab -> open editor -> close editor)
            // We remove this component store if the slide panel closes
        };
    }, []);

    // Load in the creatives
    // If the creative gets updated in the parent component (e.g. templateInput gets updated), update the creative
    useEffect(() => {
        if (!propCreatives) return;

        const loadCreatives = async () => {
            const promises: Promise<CreativeV2>[] = [];

            for (const creative of propCreatives) {
                promises.push(CreativeV2Helpers.loadTemplateForCreative(creative));
            }

            await Promise.all(promises);

            if (propCreatives && !isEqual(propCreatives, creatives)) {
                ComponentStore.setModel('CreativeOverview', 'creatives', propCreatives);
            }
            setLoading(false);
        };

        loadCreatives();
    }, [propCreatives]);

    // Give back the active frame to the parent component
    useEffect(() => {
        if (frameActions.onChangeActiveFrame && activeFrame) frameActions.onChangeActiveFrame(activeFrame);
    }, [activeFrame]);

    const isSingleTemplateCreative = (creatives: CreativeV2[] | CreativeV2[]): boolean => {
        if (!creatives) return false;
        return creatives.length === 1 && isTemplateCreative(creatives[0]);
    };

    const showPlayBar = () => {
        if (disabledFeatures.includes('playBar')) return false;
        if (!creatives) return false;

        if (isSingleTemplateCreative(creatives)) {
            return getCreativeInstance(creatives[0])?.showPlayBar();
        }
        return false;
    };

    return (
        <div className="creative-overview">
            {loading && (
                <div className="creative-overview__loading">
                    <CircularProgress></CircularProgress>
                </div>
            )}
            {!loading && !creatives?.length && <div className="creative-overview__empty">No creatives found</div>}
            {!loading && !!creatives && creatives.length > 0 && (
                <>
                    {view === 'single' && !hideSingleViewSideBar && (
                        <div className="creative-overview__single-view-side-bar">
                            <SingleViewSideBar defaultSingleViewKey={defaultSingleViewKey} />
                        </div>
                    )}
                    <div className="creative-overview__canvas-wrapper">
                        <div className="creative-overview__canvas-wrapper__zoom-bar">
                            <ZoomBar availableViews={availableViews} showEditCreativeButton={showEditCreativeButton} onSaveCreative={onSaveCreative} />
                        </div>
                        <div className="creative-overview__canvas-wrapper__canvas">
                            <Canvas iframeOverwrites={iframeOverwrites} headerComponent={headerComponent} editable={editable} />
                            {loading && (
                                <>
                                    <div className="creative-overview__canvas-wrapper__canvas__overlay"></div>
                                    <div className="creative-overview__canvas-wrapper__canvas__overlay-spinner">
                                        <CircularProgress></CircularProgress>
                                    </div>
                                </>
                            )}
                        </div>
                        {showPlayBar() && (
                            <div className="creative-overview__canvas-wrapper__play-bar">
                                <CreativeOverviewPlaybar editable={editable} frameActions={frameActions} autoplayOnFirstOpen={autoplayOnFirstOpen} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default CreativeOverview;

export interface FrameActions {
    onChangeActiveFrame?: (frameId: FramesBarFrame['id']) => void;
    onAddFrame?: () => void;
    onDeleteFrame?: (frame: FramesBarFrame) => void;
    onChangeFrameOrder?: (newFrames: FramesBarFrame[]) => void;
}

export interface CreativeOverviewProps {
    creatives: CreativeV2[] | CreativeV2[];
    editable?: boolean;
    availableViews?: CreativeViewType[];
    showEditCreativeButton?: boolean;
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    frameActions?: FrameActions;
    disabledFeatures?: DisabledFeature[];
    defaultView?: CreativeViewType;
    autoplayOnFirstOpen?: boolean;
    defaultSingleViewKey?: string;
    hideSingleViewSideBar?: boolean;
    onSaveCreative?: (creative: CreativeV2Template) => void;
}

interface ComponentStoreProps {
    view: ICreativeOverview['view'];
    activeFrame: ICreativeOverview['activeFrame'];
    creatives: ICreativeOverview['creatives'];
}
